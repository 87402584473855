import { useNavigate, useBlocker as useBlocker, useBeforeUnload, Location } from 'react-router-dom';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

const usePreventNavigation = (
  shouldPreventNavigation: boolean,
  blockCallback?: () => void,
): { unblockRef: RefObject<() => void>; doNavigation: () => void } => {
  const [returnUrl, setReturnUrl] = useState<Location | null>(null);
  const blocker = useBlocker(shouldPreventNavigation);
  const navigate = useNavigate();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blockCallback ? blockCallback() : window.confirm('Change page?\n\nChanges that you made may not be saved.');
      setReturnUrl(blocker.location);
    }
  }, [blockCallback, blocker]);

  useBeforeUnload(
    useCallback((e) => {
      e.preventDefault();
      e.returnValue = '';
      return 'Are you sure you want to leave?\n\nChanges that you made may not be saved';
    }, []),
    { capture: true },
  );

  const doReset = useRef(() => {
    setReturnUrl(null);
    blocker.reset && blocker.reset();
  });

  const doNavigation = useCallback(() => {
    if (blocker.proceed) {
      blocker.proceed();
    } else {
      doReset.current();
      returnUrl ? navigate(returnUrl) : navigate(-1);
    }
  }, [blocker, navigate, returnUrl]);

  return { unblockRef: doReset, doNavigation };
};

export default usePreventNavigation;
