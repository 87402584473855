import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import usePreventNavigation from '../../hooks/usePreventNavigation';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './modal/variants/ConfirmationModal';
import { ModalContext } from '../../contexts/ModalContext';

interface RouteGuardProps {
  when?: boolean | undefined | (() => boolean);
  onSave: () => Promise<boolean>; // boolean says whether to continue with navigation
  onContinue?: () => Promise<void>;
  title?: string;
  subtitle?: string;
  continueText?: string;
  saveText?: string;
  forceClose?: boolean;
}

const RouteGuard: FC<RouteGuardProps> = (props) => {
  const { t } = useTranslation('form-builder');
  const {
    when,
    onSave,
    onContinue,
    title = t('route-guard.title'),
    subtitle = t('route-guard.subtitle'),
    continueText = t('route-guard.continue'),
    saveText = t('route-guard.save'),
    forceClose = false,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = useCallback(() => setModalVisible(true), []);
  const whenValue = useMemo(() => {
    if (typeof when === 'function') {
      return when();
    }

    return !!when;
  }, [when]);

  const { doNavigation: goToPrevBlockedPage } = usePreventNavigation(whenValue, showModal);

  const handleConfirmNavigationClick = async () => {
    onContinue && (await onContinue());
    setModalVisible(false);
    doNavigation();
  };

  const handleSaveBeforeNavigationClick = async () => {
    const result = await onSave();
    setModalVisible(false);
    if (result) {
      doNavigation();
    }
  };

  const doNavigation = useCallback(() => {
    goToPrevBlockedPage();
  }, [goToPrevBlockedPage]);

  useEffect(() => {
    if (forceClose) {
      setModalVisible(false);
    }
  }, [forceClose]);

  return (
    <ModalContext.Provider value={{ open: modalVisible, onClose: () => setModalVisible(false) }}>
      <ConfirmationModal
        title={title}
        description={subtitle}
        confirmText={saveText || ''}
        cancelText={continueText || ''}
        onConfirm={handleSaveBeforeNavigationClick}
        onCancel={handleConfirmNavigationClick}
      ></ConfirmationModal>
    </ModalContext.Provider>
  );
};

export default RouteGuard;
